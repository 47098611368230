import {
  USER_TAG_TYPE,
  ADMIN_LIST_TAG_TYPE
} from 'store/api/constants'

const getAdminEndpoints = builder => ({
  fetchAdmin: builder.query({
    query: () => '/sessions/me',
    providesTags: [USER_TAG_TYPE],
  }),
  loginAdminSaml: builder.mutation({
    query: ({ body }) => ({
      url: '/saml',
      method: 'POST',
      body
    })
  }),
  fetchSamlLoginUrl: builder.mutation({
    query: () => ({
      url: '/saml/initiate',
      method: 'GET'
    })
  }),
  loginAdmin: builder.mutation({
    query: ({ body }) => ({
      url: '/sessions',
      method: 'POST',
      body,
    })
  }),
  impersonateUser: builder.mutation({
    query: body => ({
      url: '/user_impersonations',
      method: 'POST',
      body,
    })
  }),
  unpauseBillingCard: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/unpause_card`,
      method: 'PUT',
      body
    })
  }),
  getAdminsList: builder.query({
    query: (params = {}) => {
      const {
        perPage,
        page,
        sortField,
        sortDirection,
      } = params
      const currentpage = page || 1
      const data = { per_page: perPage || 4, page: currentpage, sort_field: sortField, sort_direction: sortDirection }

      return {
        url: '/admins',
        params: data,
      }
    },
    providesTags: [ADMIN_LIST_TAG_TYPE],
  }),
  addAdmin: builder.mutation({
    query: ({ body }) => ({
      url: '/admins',
      method: 'POST',
      body
    }),
    invalidatesTags: (_, error) => (error ? [] : [ADMIN_LIST_TAG_TYPE]),
  }),
  updateAdmin: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/admins/${accountUuid}`,
      method: 'PUT',
      body
    }),
    invalidatesTags: (_, error) => (error ? [] : [ADMIN_LIST_TAG_TYPE]),
  }),
})

export default getAdminEndpoints

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {
  Outlet,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom'

import { bootstrap } from 'store/modules/app/actions'

import AppTopbar from 'containers/Layout/AppTopbar'

const mappings = _ => ({})

const AppLayout = ({ onBootstrap }) => {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    onBootstrap(navigate)
  }, [navigate, onBootstrap])

  const isAccountDetailsRoute = !!matchPath('/app/accounts/:accountId/:menu', location.pathname)

  return (
    <>
      <AppTopbar />
      <div className={classNames('w-full pb-2', { 'px-2 md:px-12 md:pb-12': isAccountDetailsRoute })}>
        <Outlet />
      </div>
    </>
  )
}

AppLayout.propTypes = {
  onBootstrap: PropTypes.func.isRequired,
}

export default connect(mappings, {
  onBootstrap: bootstrap
})(AppLayout)

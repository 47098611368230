import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useLazyFetchSearchQuery } from 'store/api'
import SearchField from 'components/ui/SearchField'
import { ROUTES } from 'constants'
import SearchResults from 'features/Search/Results'

const SearchBar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)
  const handleSearchFocus = useCallback(() => {
    setIsSearchInputFocused(true)
  }, [setIsSearchInputFocused])

  const handleBlur = useCallback(event => {
    // we should not trigger native onBlur if we click on button roles within results list
    if (event.relatedTarget?.role !== 'button') {
      setIsSearchInputFocused(false)
    }
  }, [setIsSearchInputFocused])

  const [
    fetchSearch,
    {
      data = {},
      isFetching: isFetchingSearch,
      error,
    }
  ] = useLazyFetchSearchQuery()

  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = useCallback(value => {
    setSearchValue(value)
  }, [setSearchValue])
  const handleClearSearch = useCallback(() => setSearchValue(''), [setSearchValue])

  const handleNavigate = useCallback(item => {
    setSearchValue('')
    navigate(`/app/accounts/${item.uuid}/bills`)
  }, [
    setSearchValue,
    navigate,
  ])

  useEffect(() => {
    if (isSearchInputFocused) {
      fetchSearch({
        q: searchValue,
      })
    }
  }, [isSearchInputFocused, fetchSearch, searchValue])

  return (
    <div onBlur={handleBlur}>
      {location.pathname !== ROUTES.APP.SEARCH ? (
        <div className="flex items-center ml-7.5 mt-0.5">
          <SearchField
            value={searchValue}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            color="transparent"
            placeholder="What are you looking for today?"
          />
        </div>
      ) : null}
      {searchValue.length > 2 || isSearchInputFocused ? (
        <SearchResults
          results={data}
          isFetching={isFetchingSearch}
          error={error}
          onCloseResults={handleClearSearch}
          onNavigate={handleNavigate}
        />
      ) : null}
    </div>
  )
}

const Memoized = React.memo(SearchBar)
export default Memoized

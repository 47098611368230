import { LOAD_ACCOUNTS, LOAD_WIDGETS, LOAD_ACCOUNTS_CHART, LOAD_RECENTLY_VIEWED } from 'store/modules/dashboard/actions'

const initialState = {
  accounts: [],
  recentlyViewed: [],
  widgets: {},
  accountsChart: {
    labels: [],
    dataSets: {
      active: [],
      inactive: []
    }
  }
}

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNTS.SUCCESS:
      return {
        ...state,
        accounts: action.payload.accounts
      }
    case LOAD_WIDGETS.SUCCESS:
      return {
        ...state,
        widgets: action.payload
      }
    case LOAD_ACCOUNTS_CHART.SUCCESS:
      return {
        ...state,
        accountsChart: action.payload
      }
    case LOAD_RECENTLY_VIEWED.SUCCESS:
      return {
        ...state,
        recentlyViewed: action.payload.recentlyViewed
      }
    default:
      return state
  }
}

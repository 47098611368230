/* eslint-disable no-use-before-define */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
// import { navigate } from 'react-router-dom';
import Config from 'config'
import Connection from 'utils/connection'
import { toCamel, uuidv4, getAdminToken } from 'utils'

const client = new Connection()

const fetchMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState)
  }

  const { promise, types, ...rest } = action // eslint-disable-line no-redeclare

  if (!promise) {
    return next(action)
  }

  const token = getAdminToken()
  const requestId = uuidv4()

  client.setToken(token)
  next({ ...rest, type: types.REQUEST, requestId })

  promise.method = (promise.method && promise.method.toLowerCase()) || 'get'
  promise.host = promise.host || Config.SERVER_URL

  const actionPromise = client[promise.method](promise)
    .then(payload => Promise.resolve({ payload }))

  actionPromise
    .then(response => {
      // Transform to camelCase
      const data = {
        ...response,
        payload: toCamel(response.payload)
      }
      next({ ...rest, ...data, type: types.SUCCESS, requestId, meta: { ...action.meta } })
    })
    .catch(error => {
      if (Config.ENVIRONMENT === 'development') {
        console.error(error) // eslint-disable-line no-console
      }

      // UI expects an errors object, if is not there
      // we sould show a generic error
      if (!error.data?.errors) {
        error.data = error.data || {}
        error.data.errors = [{
          isDefaultMessage: true,
          status: error.status,
          [error.status]: getGenericServerError(error.status),
        }]
      }

      next({ ...rest, payload: error.data, type: types.FAIL, requestId })
    })

  return actionPromise
}

const messages = {
  500: 'There was an error, please try again and see if that works.',
  404: 'The resource you are looking doesn\'t exist.',
  403: 'Seems like you have no access to this resource.',
  default: 'Seems like there was an unknown error, please let us know!',
}
function getGenericServerError(status) {
  return messages[status] || messages.default
}

export default fetchMiddleware

/* eslint-disable no-useless-escape */

const NUMBERS_ONLY_REGEX = /[^\d]/g
const VALID_EMAIL_REGEX
  = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = email => Boolean(
  email.match(VALID_EMAIL_REGEX)
)

export const formatPhoneNumber = (value, previousValue) => {
  // return nothing if no value
  if (typeof value !== 'string') return ''

  // only allows 0-9 inputs
  const currentValue = value.replace('+1', '').replace(NUMBERS_ONLY_REGEX, '')
  const cvLength = currentValue.length

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue

    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }

  return ''
}

export const onlyNumbers = value => value.replace(/[^0-9]/g, '')

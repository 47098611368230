import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/ui/Button'
import FastTextField from 'components/ui/FastTextField'
import styles from './styles.module.scss'

const SearchField = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    color,
    ...otherProps
  } = props

  const handleClearInput = useCallback(() => onChange(''), [onChange])

  return (
    <FastTextField
      ref={ref}
      type="text"
      name="search"
      startAdornment={(
        <FontAwesomeIcon
          icon="far fa-magnifying-glass"
          className={`pt-2
            ${color === 'default' ? '' : null}
            ${color === 'transparent' ? 'text-white' : null}
          `}
        />
      )}
      className={`
        w-122.5 px-2.5
        ${color === 'default' ? styles['light-fast-text-field'] : null}
        ${color === 'transparent' ? styles['dark-fast-text-field'] : null}
      `}
      inputProps={{
        className: `!pt-1
          ${color === 'default' ? 'bg-transparent' : null}
          ${color === 'transparent' ? 'text-white placeholder-white' : null}
        `
      }}
      rounded
      isSearchField
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      endAdornment={value ? (
        <Button
          onClick={handleClearInput}
          variant={`
            ${color === 'default' ? '' : null}
            ${color === 'transparent' ? 'contained' : null}
          `}
          size=""
          className={`
            "w-6 h-6 hover:bg-transparent pt-2.5
            ${color === 'default' ? 'text-black' : null}
            ${color === 'transparent' ? 'text-white' : null}
          `}
        >
          <FontAwesomeIcon icon="far fa-circle-xmark" />
        </Button>
      ) : null}
      {...otherProps}
    />
  )
})
SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  color: PropTypes.string,
}
SearchField.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  color: 'default',
}

export default React.memo(SearchField)

import React, { Suspense, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { ROUTES, HEROKU_ENVS } from 'constants'
import CONFIG from 'config'
import AuthRoute from 'containers/AuthRoute'
import NonAuthRoute from 'containers/NonAuthRoute'
import Site from 'containers/Layout/Site'
import AppLayout from 'containers/Layout/AppLayout'
import NotFound from 'components/ui/ErrorFallback'
import { PERMISSIONS } from 'constants/permissions'
import ProtectedRoute from 'components/routes/ProtectedRoute'

// Async pages
const Login = lazy(() => import('pages/Login'))
const LoginSaml = lazy(() => import('pages/LoginSaml'))
const Logout = lazy(() => import('pages/Logout'))
const Dashboard = lazy(() => import('pages/Dashboard'))
const Accounts = lazy(() => import('pages/Accounts'))
const AccountDetails = lazy(() => import('pages/AccountDetails'))
const Admins = lazy(() => import('pages/Admins'))
const PromoCodes = lazy(() => import('pages/PromoCodes'))
const SeedUsers = lazy(() => import('pages/SeedUsers'))
const Search = lazy(() => import('pages/Search'))

const Pages = () => {
  const location = useLocation()

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes key={location.pathname} location={location}>
        <Route
          path={ROUTES.ROOT}
          element={(
            <NonAuthRoute>
              <Site />
            </NonAuthRoute>
          )}
        >
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.LOGIN_SAML} element={<LoginSaml />} />
        </Route>
        <Route path={ROUTES.LOGOUT} element={<Logout />} />
        <Route
          path={ROUTES.APP.ROOT}
          element={(
            <AuthRoute>
              <AppLayout />
            </AuthRoute>
          )}
        >
          <Route path={ROUTES.APP.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.APP.ACCOUNTS} element={<Accounts />} />
          <Route path={ROUTES.APP.ACCOUNTS_DETAILS} element={<AccountDetails />} />
          <Route
            path={ROUTES.APP.ADMINS}
            element={(
              <ProtectedRoute permissions={{ [PERMISSIONS.VIEW_ADMIN_LIST]: true }}>
                <Admins />
              </ProtectedRoute>
            )}
          />
          <Route path={ROUTES.APP.PROMO_CODES} element={<PromoCodes />} />
          {CONFIG.HEROKU_APP_ENV !== HEROKU_ENVS.PRODUCTION ? <Route path={ROUTES.APP.SEED_USERS} element={<SeedUsers />} /> : null}
          <Route path={ROUTES.APP.SEARCH} element={<Search />} />
        </Route>

        <Route
          path="*"
          element={(
            <NotFound
              message="The page you requested could not be found. Please check the URL and try again."
              isRouteNotFound
              location={location}
            />
          )}
        />
      </Routes>
    </Suspense>
  )
}

export default Pages

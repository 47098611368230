import {
  LOAD_ACCOUNT,
  SHOW_SECURITY_MODAL,
  SET_ACCOUNTS_TABLE_SETTING,
  PAUSE_ACCOUNT,
  LOCK_ACCOUNT,
  UNLOCK_ACCOUNT,
  VERIFY_IDENTITY,
  CLOSE_ACCOUNT,
  REOPEN_ACCOUNT,
  DEFER_MEMBERSHIP_DUE_DATE,
} from 'store/modules/accounts/actions'

const initialState = {
  account: {
    account: {},
    subscription: {},
  },
  securityModals: {
    resetModal: false,
    verificationModal: false,
    pinModal: false
  },
}

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
        activeMenu: 'accounts'
      }
    case PAUSE_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case LOCK_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case UNLOCK_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case VERIFY_IDENTITY.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case CLOSE_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case REOPEN_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case DEFER_MEMBERSHIP_DUE_DATE.SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case SHOW_SECURITY_MODAL:
      return {
        ...state,
        securityModals: {
          ...state.securityModals,
          [action.payload.modal]: action.payload.show
        },
      }
    case SET_ACCOUNTS_TABLE_SETTING:
      return {
        ...state,
        accountsTableSettings: action.payload.settings
      }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'containers/ErrorBoundary/Fallback'

const ErrBoundary = props => {
  const {
    children,
  } = props

  const handleError = (
    // error,
    // info,
  ) => {
    // Do something additional with the error
  }

  const handleReset = () => {
    // reset the state of your app so the error doesn't happen again
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={handleReset}
    >
      {children}
    </ErrorBoundary>
  )
}
ErrBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(ErrBoundary)

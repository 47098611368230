export const filterEmptyValues = obj => {
  const filteredObj = Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key]
    }
    return acc
  }, {})

  return filteredObj
}

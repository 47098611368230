import { generateActions } from 'utils'

export const LOGIN_ADMIN = generateActions('auth/LOGIN_ADMIN')
export const LOAD_CURRENT_ADMIN = generateActions('auth/LOAD_CURRENT_ADMIN')
export const SET_TOKEN = 'auth/SET_TOKEN'
export const LOGOUT_ADMIN = 'auth/LOGOUT_ADMIN'

export function loadCurrentAdmin() {
  return {
    types: LOAD_CURRENT_ADMIN,
    promise: {
      url: '/sessions/me',
      method: 'GET',
      data: {}
    },
  }
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: {
      token,
    },
  }
}

export const logoutAdmin = () => ({
  type: LOGOUT_ADMIN,
})

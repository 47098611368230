import runtimeEnv from '@mars/heroku-js-runtime-env'

const buildTimeEnv = runtimeEnv()
const compileTimeEnv = buildTimeEnv.REACT_APP_HEROKU_APP_ENV ? buildTimeEnv : process.env

const config = {
  COOKIE_TOKEN: 'com.stellar.adminToken',
  ENVIRONMENT: compileTimeEnv.NODE_ENV,
  HEROKU_APP_ENV: compileTimeEnv.REACT_APP_HEROKU_APP_ENV,
  HEROKU_SLUG_COMMIT: compileTimeEnv.REACT_APP_HEROKU_SLUG_COMMIT,
  SENTRY_DSN: compileTimeEnv.REACT_APP_SENTRY_DSN,
  SERVER_URL: `${compileTimeEnv.REACT_APP_SERVER_API_URL}/${compileTimeEnv.REACT_APP_API_VERSION}`,
  CLIENT_URL: compileTimeEnv.REACT_APP_CLIENT_URL
}

export default config

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { captureMessage as captureSentryMessage } from '@sentry/react'

import CONFIG from 'config'
import Card from 'components/ui/Card'
import Button from 'components/ui/Button'
import DogInHelmet from 'assets/images/dog-in-helmet.png'
import Rocks from 'assets/images/rocks.png'

const ErrorFallback = props => {
  const {
    message,
    error,
    onReset,
    isRouteNotFound,
    location,
  } = props

  useEffect(() => {
    if (isRouteNotFound) {
      captureSentryMessage(`User tried to enter a non-existing route: ${location.pathname || ''}`)
    }
  }, [isRouteNotFound, location.pathname])

  return (
    <div className="h-screen flex">
      <Card
        id="app-error"
        className="relative w-2/5 m-auto group"
      >
        <h1 className="text-pink text-5xl text-center font-bold mb-3">
          Oops!
        </h1>
        <p className="text-base font-normal text-center">
          {message}
        </p>
        <img
          src={DogInHelmet}
          alt="dog in helmet"
          className="absolute -left-16 -top-24"
        />
        <img
          src={Rocks}
          alt="rocks"
          className="absolute -right-16 -top-12"
        />

        {error && CONFIG.ENVIRONMENT === 'development' ? (
          <>
            <pre className="text-center mb-2 whitespace-pre-wrap break-words">
              {error?.stack}
            </pre>
            <Button
              onClick={onReset}
              variant="contained"
              color="red"
              className="w-full"
            >
              Try again
            </Button>
          </>
        ) : null}
      </Card>
    </div>
  )
}
ErrorFallback.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  onReset: PropTypes.func,
  isRouteNotFound: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}
ErrorFallback.defaultProps = {
  error: null,
  onReset: () => {},
  isRouteNotFound: false,
  location: {},
}

export default React.memo(ErrorFallback)

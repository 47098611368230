import { LOGIN_ADMIN, LOAD_CURRENT_ADMIN, SET_TOKEN } from 'store/modules/auth/actions'

const initialState = {
  admin: {
    first_name: '',
    last_name: '',
    email: '',
  },
  jwtToken: '',
  permissions: [],
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_ADMIN.SUCCESS:
      return {
        ...state,
        admin: action.payload.admin,
        jwtToken: action.payload.jwtToken,
        permissions: action.payload.permissions,
      }
    case LOAD_CURRENT_ADMIN.SUCCESS:
      return {
        ...state,
        admin: action.payload.admin,
        jwtToken: action.payload.jwtToken,
        permissions: action.payload.permissions,
      }
    case SET_TOKEN:
      return {
        ...state,
        jwtToken: action.payload.token,
        permissions: action.payload.permissions,
      }
    default:
      return state
  }
}

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Tippy from '@tippyjs/react'
import classNames from 'classnames'

const Popover = props => {
  const {
    className,
    target,
    children,
    placement,
    disableClose
  } = props

  const [visible, setVisible] = useState(false)
  const handleOpen = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    setVisible(true)
  }, [setVisible])
  const handleClose = useCallback(e => {
    if (e.target.tagName !== 'A') {
      e.preventDefault()
    }
    e.stopPropagation()
    if (!disableClose) setVisible(false)
  }, [disableClose, setVisible])
  const handleOutsideClose = useCallback(() => setVisible(false), [setVisible])

  return (
    <Tippy
      theme="light-border"
      animation="shift-toward-extreme"
      interactive
      allowHTML
      arrow={false}
      placement={placement}
      delay={[0, 30000]}
      maxWidth="none"
      visible={visible}
      onClickOutside={handleOutsideClose}
      content={(
        <div
          className="p-2"
          onClick={handleClose}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          {children}
        </div>
      )}
    >
      <span
        tabIndex="0"
        className={classNames('cursor-pointer', className)}
        onClick={handleOpen}
        role="button"
        aria-hidden="true"
      >
        {target}
      </span>
    </Tippy>
  )
}
Popover.propTypes = {
  className: PropTypes.string,
  target: PropTypes.node,
  children: PropTypes.node,
  placement: PropTypes.string,
  disableClose: PropTypes.bool
}
Popover.defaultProps = {
  className: '',
  target: null,
  children: null,
  placement: 'bottom-end',
  disableClose: false
}

export default Popover

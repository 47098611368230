import CONFIG from 'config'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  getAdminToken,
  toCamel,
  camelToSnakeCaseObj,
} from 'utils'

import {
  USER_TAG_TYPE,
  ADMIN_LIST_TAG_TYPE,
  PROMO_LIST_TAG_TYPE
} from './constants'
import getAlertEndpoints from './alert'
import getAccountEndpoints from './account'
import getAdminEndpoints from './admin'
import getPromoCodesEndpoints from './promocodes'
import getSearchEndpoints from './search'
import getSeedUsersEndopoints from './seedusers'
import getHighnoteQaEndpoints from './highnoteqa'

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.SERVER_URL,
  prepareHeaders: headers => {
    const token = getAdminToken()

    // TODO: when signing up or logging in, check if token is ready to be used right away
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    // we need to delete this header in order for the browser to set it correctly when sending files
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type')
    }

    return headers
  },
})

const api = createApi({
  reducerPath: 'api',
  baseQuery: async (args, apiEntity, extraOptions) => {
    const parsedArgs = typeof args === 'object' && args !== null ? {
      ...args,
      ...(args.params ? { params: camelToSnakeCaseObj(args.params) } : {}),
      ...(args.body ? {
        body: args.headers?.['Content-Type'] === 'multipart/form-data' ? args.body : camelToSnakeCaseObj(args.body),
      } : {}),
    } : args

    const result = await baseQuery(parsedArgs, apiEntity, extraOptions)
    return { ...result, data: toCamel(result.data) }
  },
  tagTypes: [USER_TAG_TYPE, ADMIN_LIST_TAG_TYPE, PROMO_LIST_TAG_TYPE],
  endpoints: builder => ({
    ...getAlertEndpoints(builder),
    ...getAccountEndpoints(builder),
    ...getPromoCodesEndpoints(builder),
    ...getSearchEndpoints(builder),
    ...getAdminEndpoints(builder),
    ...getSeedUsersEndopoints(builder),
    ...getHighnoteQaEndpoints(builder)
  }),
})

export const {
  // account
  useCloseAccountMutation,
  useLinkPaymentToBillerMutation,
  useLockAccountMutation,
  useUpdateProfileMutation,
  useOverrideSpendLimitMutation,
  useRepayPaymentOrderMutation,
  useRepaySubscriptionBillMutation,
  useWriteOffSubscriptionBillMutation,
  useUploadDocumentMutation,
  usePauseBillingCardMutation,
  useUnpauseBillingCardMutation,
  useIssueBankruptcyMutation,
  useReplaceBillingCardMutation,
  useArchiveBankMutation,
  useUnlockBankMutation,
  useRefundPaymentMutation,
  useRefundOverCollectionMutation,
  useFraudTransactionMutation,
  useGetAccountListQuery,
  useLazyGetAccountListQuery,
  useRetryCreditServiceRegistrationMutation,
  useResetDocumentVerificationMutation,
  useFetchAccountAlertsQuery,
  useFetchAccountEmailsQuery,
  useFetchAccountEventsQuery,
  useFetchAccountPaymentOrdersQuery,
  useFetchAccountTransactionsQuery,
  useFetchAccountBillPayCardsQuery,
  useForgetAccountMutation,
  useFetchAccountAgreementsQuery,
  useChangeFraudTypeMutation,
  // alert
  useGetFiltersQuery,
  useGetAlertsListQuery,
  useLazyGetAlertsListQuery,
  // admin
  useFetchAdminQuery,
  useImpersonateUserMutation,
  useGetAdminsListQuery,
  useLazyGetAdminsListQuery,
  useLoginAdminSamlMutation,
  useFetchSamlLoginUrlMutation,
  useLoginAdminMutation,
  useAddAdminMutation,
  useUpdateAdminMutation,
  // promocodes
  useUpdatePromoCodeMutation,
  useAddPromoCodeMutation,
  useRedeemPromoCodeMutation,
  useGetPromoCodesListQuery,
  useLazyGetPromoCodesListQuery,
  // search
  useFetchSearchQuery,
  useLazyFetchSearchQuery,
  // seed users
  useGetSeedUsersListQuery,
  useLazyGetSeedUsersListQuery,
  useAddSeedUserMutation,
  useDeleteSeedUserMutation,
  // highnote Qa
  useSimulateHighnoteAuthMutation
} = api

export default api

/* eslint-disable no-restricted-globals */
const toString = Object.prototype.toString

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is an array or not
 */
function isArray(value) {
  return ('isArray' in Array) ? Array.isArray(value) : toString.call(value) === '[object Array]'
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is a date or not
 */
function isDate(value) {
  return toString.call(value) === '[object Date]'
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is an object or not
 */
function isObject(value) {
  return value !== null && !isArray(value) && typeof value === 'object'
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is an string or not
 */
function isString(value) {
  return typeof value === 'string'
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is a number or not
 */
function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is a boolean or not
 */
function isBoolean(value) {
  return typeof value === 'boolean'
}

/**
 * @param {Any} value - The value to check
 * @return {Boolean} Whether is a function or not
 */
function isFunction(value) {
  // Safari 3.x and 4.x returns 'function' for typeof <NodeList>, hence we need
  // to fall back to using Object.prototype.toString (slower)
  if (typeof document !== 'undefined' && typeof document.getElementsByTagName('body') === 'function') {
    return toString.call(value) === '[object Function]'
  }

  return typeof value === 'function'
}

export const Types = {
  isArray,
  isBoolean,
  isDate,
  isFunction,
  isObject,
  isNumber,
  isString,
}

/* eslint react/prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCurrentAdmin } from 'store/modules/auth/selectors'

import CONFIG from 'config'
import { PERMISSIONS, ROUTES, HEROKU_ENVS } from 'constants'

import Avatar from 'components/ui/Avatar'
import Popover from 'components/ui/Popover'
import Button from 'components/ui/Button'
import Link from 'components/ui/Link'
import logo from 'assets/logo.svg'
import SearchBar from 'containers/Layout/SearchBar'
import Can from 'containers/Flags/Can'

const mappings = state => ({
  admin: getCurrentAdmin(state)
})

const AppTopbar = ({ admin }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full bg-black py-3 px-2 md:px-12 text-white border-b border-white">
        <div className="container mx-auto px-2">
          <div className="w-full flex flex-row items-center">
            <Link to={ROUTES.APP.DASHBOARD}>
              <img src={logo} alt="stellar" style={{ width: 130 }} />
            </Link>
            <SearchBar />
            <div className="ml-auto flex items-center">
              <Link
                href="https://stellarfi.zendesk.com/access/unauthenticated"
                target="_blank"
                rel="noreferrer"
                className="no-underline  mr-8"
                size="md"
                color="red"
              >
                Sign in to Zendesk
              </Link>
              <Popover target={<Avatar label={`${admin?.firstName?.charAt(0)}${admin?.lastName?.charAt(0)}`} size="sm" />}>
                <div className="pr-16">
                  <Button
                    className="block font-medium hover:bg-white"
                    size=""
                    onClick={() => navigate(ROUTES.LOGOUT)}
                  >
                    Log Out
                  </Button>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-black py-2 px-2 md:px-12">
        <div className="flex flex-wrap gap-4 container mx-auto px-2">
          <Link
            color="red"
            className="text-sm px-2 no-underline"
            to={ROUTES.APP.DASHBOARD}
          >
            Home
          </Link>
          <Link
            color="red"
            className="text-sm px-2 no-underline"
            to={ROUTES.APP.ACCOUNTS}
          >
            Members
          </Link>
          <Can permissions={{ [PERMISSIONS.VIEW_ADMIN_LIST]: true }}>
            <Link
              color="red"
              className="text-sm px-2 no-underline"
              to={ROUTES.APP.ADMINS}
            >
              Admins
            </Link>
          </Can>
          <Link
            color="red"
            className="text-sm px-2 no-underline"
            to={ROUTES.APP.PROMO_CODES}
          >
            Promo Codes
          </Link>
          {CONFIG.HEROKU_APP_ENV !== HEROKU_ENVS.PRODUCTION ? (
            <Link
              color="red"
              className="text-sm px-2 no-underline"
              to={ROUTES.APP.SEED_USERS}
            >
              Seed Users
            </Link>
          ) : null}
          <Link
            color="red"
            className="text-sm px-2 no-underline"
            to={ROUTES.APP.SEARCH}
          >
            Advanced search
          </Link>
        </div>
      </div>
    </>
  )
}

AppTopbar.propTypes = {
  admin: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired
}

export default connect(mappings, {})(AppTopbar)

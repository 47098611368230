import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ROUTES } from 'constants'

import logo from 'assets/logo.svg'
import Link from 'components/ui/Link'

export default function SiteTopbar({ hasBg }) {
  return (
    <div className="py-4 px-6 w-full absolute z-10 top-0">
      <div className="flex justify-between items-center">
        {!hasBg
          ? (
            <Link
              to={ROUTES.ROOT}
              className={`no-underline font-normal ${hasBg ? 'text-white' : 'text-default' }`}
              color="red"
              size="md"
            >
              <FontAwesomeIcon
                icon="fa fa-chevron-left"
                size="sm"
                className="mr-2"
              />
              Back
            </Link>
          )
          : <span />}
        <Link to="/" size="lg">
          <img src={logo} className="w-5 h-auto" alt="logo" style={{ width: 130 }} />
        </Link>
        <Link
          to={ROUTES.LOGIN}
          className={`${hasBg ? 'text-white' : 'text-default' } no-underline font-normal`}
          color="red"
          size="md"
        >
          Login
        </Link>
      </div>
    </div>
  )
}

SiteTopbar.propTypes = {
  hasBg: PropTypes.bool.isRequired,
}

export const sortStrings = (stringA = '', stringB = '') => {
  const fullNameA = stringA?.toLowerCase()
  const fullNameB = stringB?.toLowerCase()
  if (fullNameA < fullNameB) return -1
  if (fullNameA > fullNameB) return 1
  return 0
}

export const sortNumbers = (numberA, numberB) => {
  const fullNameA = Number(numberA)
  const fullNameB = Number(numberB)
  if (fullNameA < fullNameB) return -1
  if (fullNameA > fullNameB) return 1
  return 0
}

export const sortDates = (dateA, dateB) => {
  if (dateA < dateB) return -1
  if (dateA > dateB) return 1
  return 0
}

export const sortStatuses = (statusA, statusB) => {
  const rowAFullName = statusA ? 'disabled' : 'active'
  const rowBFullName = statusB ? 'disabled' : 'active'
  if (rowAFullName < rowBFullName) return -1
  if (rowAFullName > rowBFullName) return 1
  return 0
}

import { generateActions } from 'utils'

export const LOAD_ACCOUNT = generateActions('accounts/LOAD_ACCOUNT')
export const SHOW_SECURITY_MODAL = 'accounts/SHOW_SECURITY_MODAL'
export const SEND_RESET_EMAIL = generateActions('accounts/SEND_RESET_EMAIL')
export const PAUSE_ACCOUNT = generateActions('accounts/PAUSE_ACCOUNT')
export const LOCK_ACCOUNT = generateActions('accounts/LOCK_ACCOUNT')
export const UNLOCK_ACCOUNT = generateActions('accounts/UNLOCK_ACCOUNTT')
export const CLOSE_ACCOUNT = generateActions('accounts/CLOSE_ACCOUNT')
export const REOPEN_ACCOUNT = generateActions('accounts/REOPEN_ACCOUNT')
export const REFUND_SUBSCRIPTION = generateActions('accounts/REFUND_SUBSCRIPTION')
export const CREATE_USER_NOTE = generateActions('accounts/CREATE_USER_NOTE')
export const VERIFY_IDENTITY = generateActions('accounts/VERIFY_IDENTITY')
export const SEND_VERIFICATION_EMAIL = generateActions('accounts/SEND_VERIFICATION_EMAIL')
export const SEND_PIN_CODE = generateActions('accounts/SEND_PIN_CODE')
export const VERIFY_PIN_CODE = generateActions('accounts/VERIFY_PIN_CODE')
export const SET_ACCOUNTS_TABLE_SETTING = 'accounts/SET_ACCOUNTS_TABLE_SETTING'
export const DEFER_MEMBERSHIP_DUE_DATE = generateActions('accounts/DEFER_MEMBERSHIP_DUE_DATE')

export function loadAccount(accountId) {
  return ({
    types: LOAD_ACCOUNT,
    promise: {
      url: `/accounts/${accountId}`,
      method: 'GET',
    }
  })
}

export function showSecurityModal(modal, show) {
  return {
    type: SHOW_SECURITY_MODAL,
    payload: { modal, show }
  }
}

export function sendResetEmail(accountId) {
  return ({
    types: SEND_RESET_EMAIL,
    promise: {
      url: `/accounts/${accountId}/send_password_reset.json`,
      method: 'POST',
      data: { }
    }
  })
}

export function pauseAccount(accountId, data) {
  return ({
    types: PAUSE_ACCOUNT,
    promise: {
      url: `/accounts/${accountId}/pause`,
      method: 'PUT',
      data
    }
  })
}

export function lockAccount(accountId, data) {
  return ({
    types: LOCK_ACCOUNT,
    promise: {
      url: `/accounts/${accountId}/lock`,
      method: 'PUT',
      data
    }
  })
}

export function unlockAccount(accountId) {
  return ({
    types: UNLOCK_ACCOUNT,
    promise: {
      url: `/accounts/${accountId}/unlock`,
      method: 'PUT',
      data: { }
    }
  })
}

export function reopenAccount(accountId, data) {
  return ({
    types: REOPEN_ACCOUNT,
    promise: {
      url: `/accounts/${accountId}/reopen`,
      method: 'PUT',
      data
    }
  })
}

export function deferMembershipDueDate(accountId, data) {
  return ({
    types: DEFER_MEMBERSHIP_DUE_DATE,
    promise: {
      url: `/accounts/${accountId}/defer_membership_due_date`,
      method: 'PUT',
      data
    }
  })
}

export function refundSubscription(accountId, billUuid) {
  return ({
    types: REFUND_SUBSCRIPTION,
    promise: {
      url: `/accounts/${accountId}/subscription_bills/${billUuid}/refund`,
      method: 'POST'
    }
  })
}

export function createUserNote(accountId, data) {
  return ({
    types: CREATE_USER_NOTE,
    promise: {
      url: `/accounts/${accountId}/user_notes`,
      method: 'POST',
      data
    }
  })
}

export function verifyIdentity(accountId, data) {
  return ({
    types: VERIFY_IDENTITY,
    promise: {
      url: `/accounts/${accountId}/verify_identity`,
      method: 'PUT',
      data
    }
  })
}

export function sendVerificationEmail(accountId) {
  return ({
    types: SEND_VERIFICATION_EMAIL,
    promise: {
      url: `/accounts/${accountId}/send_verification_email.json`,
      method: 'POST',
      data: { }
    }
  })
}

export function sendPinCode(accountId) {
  return ({
    types: SEND_PIN_CODE,
    promise: {
      url: `/accounts/${accountId}/send_pin_code.json`,
      method: 'POST',
      data: { }
    }
  })
}

export function verifyPinCode(accountId, pinCode) {
  return ({
    types: VERIFY_PIN_CODE,
    promise: {
      url: `/accounts/${accountId}/validate_pin_code.json`,
      method: 'POST',
      data: { pinCode }
    }
  })
}

import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useCan } from 'hooks/useCan'
import { ROUTES } from 'constants'

const ProtectedRoute = ({ children, permissions }) => {
  const can = useCan(permissions)
  if (!can) {
    return <Navigate to={ROUTES.APP.DASHBOARD} replace />
  }
  return children
}
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.shape({}).isRequired,
}

const Memoized = React.memo(ProtectedRoute)
export default Memoized

import { SEED_USERS_TAG_TYPE } from 'store/api/constants'

const getSeedUsersEndopoints = builder => ({
  getSeedUsersList: builder.query({
    query: (params = {}) => {
      const {
        perPage,
        page,
        sortField,
        sortDirection,
      } = params
      const currentpage = page || 1
      const data = { per_page: perPage || 4, page: currentpage, sort_field: sortField, sort_direction: sortDirection }

      return {
        url: '/qa/seed_users',
        params: data,
      }
    },
    providesTags: [SEED_USERS_TAG_TYPE],
  }),
  addSeedUser: builder.mutation({
    query: ({ body }) => ({
      url: '/qa/seed_users',
      method: 'POST',
      body
    }),
    invalidatesTags: (_, error) => (error ? [] : [SEED_USERS_TAG_TYPE]),
  }),
  deleteSeedUser: builder.mutation({
    query: ({ uuid }) => ({
      url: `/qa/seed_users/${uuid}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, error) => (error ? [] : [SEED_USERS_TAG_TYPE]),
  })
})

export default getSeedUsersEndopoints

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import TextField from 'components/ui/TextField'

// text field with debouncing logic (use when doing API calls on input change)
const FastTextField = React.forwardRef((props, ref) => {
  const { value, onChange, timeout } = props

  const [inputValue, setInputValue] = useState(value ?? '')
  const [t, setT] = useState(null)

  const customOnChange = val => {
    setInputValue(val)

    if (t) clearTimeout(t)
    if (val) {
      setT(setTimeout(() => onChange(val), timeout))
    } else {
      // if value is falsy - we have to immediately set the value without timeout to prevent having a visual delay on the UI
      onChange(val)
    }
  }

  // if the input value has changed from the outside - we need to force state change
  useEffect(() => {
    if (value !== inputValue) {
      customOnChange(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <TextField
      {...props}
      ref={ref}
      onChange={customOnChange}
      value={inputValue}
    />
  )
})
FastTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  timeout: PropTypes.number,
}
FastTextField.defaultProps = {
  timeout: 1000,
}

export default React.memo(FastTextField)
